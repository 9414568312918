import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const CookiePolicy = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Cookies policy"
      lang={lang}
      translationPL="/polityka-cookies/"
      translationDE="/de/cookie-politik/"
    >
      <SmallBanner title="Cookies policy" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p>
                This Cookie Policy applies to the palettenwerk.pl website. By
                using the palettenwerk.pl website, you consent to the use of
                cookies in accordance with this Cookie Policy. If you do not
                agree to our use of cookies, you should change your browser
                settings appropriately or opt out of using palettenwerk.pl.
              </p>
              <h4>What are cookies?</h4>
              <p>
                Cookies are small files that are saved and stored on your
                computer, tablet or smartphone while you visit various websites
                on the Internet. A cookie usually contains the name of the
                website it comes from, the cookie's "lifespan" (that is, its
                lifetime), and a randomly generated unique number used to
                identify the browser from which the website is connected.
              </p>
              <h4>What we use cookies for?</h4>
              <p>
                palettenwerk.pl uses cookies for various purposes: to make the
                website run faster and easier to use, to collect anonymous,
                aggregated statistics that allow us to understand how people use
                our pages and help improve their functionality and content. By
                using cookies in the manner described above, we never identify
                the identity of users on the basis of information stored in
                cookies.
              </p>
              <h4>For how long is the data stored in cookies?</h4>
              <p>
                Two types of cookies can be used on palettenwerk.pl: “session”
                and “permanent”. The former remain on your device only when
                using the our website, while permanent cookies remain on your
                device as long as they have a set lifetime or until you delete
                them.
              </p>
              <h4>Types of cookies used by palettenwerk.pl</h4>
              <p>
                Necessary for the proper functioning of our website They allow
                you to navigate through them and use their elements. For
                example, they can remember previous activities (e.g. open texts)
                when returning to the page in the same session.
              </p>
              <p>
                Improving efficiency - collecting information on how visitors
                use the palettenwerk.p website by providing information on the
                areas they visit, the time they spend on them and the problems
                they encounter, such as error messages. This allows us to
                improve the functioning of our website.
              </p>
              <h4>Are we using third-party cookies?</h4>
              <p>
                Yes, when using palettenwerk.pl you can receive cookies from
                third parties cooperating with palettenwerk.pl, such as Facebook
                or Google. You can find more information on these cookies on the
                websites of individual third parties.
              </p>
              <h4>How can I change cookie settings or delete them?</h4>
              <p>
                Most web browsers are initially set to automatically accept
                cookies. However, you can change your browser settings so that
                cookies are blocked - in whole or in part, e.g. only from third
                parties, or to receive a message each time cookies are sent to
                your device. Remember, however, that if you block the cookies we
                use, it may adversely affect the convenience of using the
                palettenwerk.pl website, for example, you may not be able to
                visit certain areas of the website or not receive personalized
                information while browsing them. You will also prevent us from
                collecting anonymous information on the use of our website in
                order to constantly improve its content.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CookiePolicy;
